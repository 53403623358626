exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-js": () => import("./../../../src/pages/activation.js" /* webpackChunkName: "component---src-pages-activation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-success-freespin-js": () => import("./../../../src/pages/success-freespin.js" /* webpackChunkName: "component---src-pages-success-freespin-js" */),
  "component---src-pages-success-full-js": () => import("./../../../src/pages/success-full.js" /* webpackChunkName: "component---src-pages-success-full-js" */),
  "component---src-templates-category-paginated-js": () => import("./../../../src/templates/category/paginated.js" /* webpackChunkName: "component---src-templates-category-paginated-js" */),
  "component---src-templates-category-regular-js": () => import("./../../../src/templates/category/regular.js" /* webpackChunkName: "component---src-templates-category-regular-js" */),
  "component---src-templates-category-search-js": () => import("./../../../src/templates/category/search.js" /* webpackChunkName: "component---src-templates-category-search-js" */),
  "component---src-templates-games-game-js": () => import("./../../../src/templates/games/game.js" /* webpackChunkName: "component---src-templates-games-game-js" */),
  "component---src-templates-page-banking-js": () => import("./../../../src/templates/page/banking.js" /* webpackChunkName: "component---src-templates-page-banking-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page/contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-goldenslot-mobile-js": () => import("./../../../src/templates/page/goldenslot-mobile.js" /* webpackChunkName: "component---src-templates-page-goldenslot-mobile-js" */),
  "component---src-templates-page-goldenslot-on-website-js": () => import("./../../../src/templates/page/goldenslot-on-website.js" /* webpackChunkName: "component---src-templates-page-goldenslot-on-website-js" */),
  "component---src-templates-page-register-js": () => import("./../../../src/templates/page/register.js" /* webpackChunkName: "component---src-templates-page-register-js" */),
  "component---src-templates-page-regular-js": () => import("./../../../src/templates/page/regular.js" /* webpackChunkName: "component---src-templates-page-regular-js" */),
  "component---src-templates-post-blog-js": () => import("./../../../src/templates/post/blog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */),
  "component---src-templates-post-promotion-js": () => import("./../../../src/templates/post/Promotion.js" /* webpackChunkName: "component---src-templates-post-promotion-js" */)
}

